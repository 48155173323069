// @ts-ignore
import * as React from "react";
import {QueryClient, QueryClientProvider,} from 'react-query'
import {createWebStoragePersistor} from "react-query/createWebStoragePersistor-experimental";
import {persistQueryClient} from "react-query/persistQueryClient-experimental";
// @ts-ignore
require("./src/css/index.css");
// @ts-ignore
// {ThemeProvider} from './src/context/ThemeContext';
var ThemeProvider = require("./src/context/ThemeContext").ThemeProvider;
// @ts-ignore
//import {Layout} from "./src/components";
var Layout = require("./src/components").Layout;
// @ts-ignore
require("./src/css/base.css");
// @ts-ignore
//import {Location} from '@reach/router';
// @ts-ignore
var Helmet = require("react-helmet").Helmet;
const {reportWebVitals} = require("./src/util/reportWebVitals");
const ReactDOM = require("react-dom");

const localStoragePersistor = createWebStoragePersistor({storage: window.localStorage})

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 500 * 60 * 1000,
        },
    },
});

persistQueryClient({
    maxAge: 500 * 60 * 1000,
    queryClient,
    persistor: localStoragePersistor,
});

// Logs when the client route changes
export const onRouteUpdate = ({location, prevLocation}) => {
    if (location.hash) {
        const hash = location.hash;
        history.replaceState({}, document.title, window.location.href.split('#')[0]);

        const offsetTop = document.getElementById(hash.substring(1)).offsetTop;

        scroll({
            top: offsetTop - 100,
        });
    }
}

export const wrapRootElement = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider>
                <Helmet>
                    <link rel="preconnect" href="https://fonts.googleapis.com"/>
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
                    <link
                        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                        rel="stylesheet"
                    />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
                        rel="stylesheet"/>
                    <link
                        href="https://fonts.googleapis.com/css2?family=JetBrains+Mono&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap"
                        rel="stylesheet"
                    />
                </Helmet>
                {props.element}
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export const wrapPageElement = ({element, props}) => {
    return <Layout {...props}>{element}</Layout>;
};
