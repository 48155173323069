import * as React from "react";

interface SearchBarProps {
    onChange?: (val: string) => void;
    value?: string;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onBlur?: (e: any) => void;
}

const SearchBar = (props: SearchBarProps) => {
    return <div
        className="border flex items-center py-1.5 rounded-md focus-within:shadow-lg dark:text-gray-300 focus:ring-4">
        <div className="grid place-items-center w-12 ">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
            </svg>
        </div>
        <input onBlur={(e) => {
            if (props.onBlur) {
                props.onBlur(e);
            }
        }} className="text-sm pr-2 w-full h-full outline-none dark:bg-gray-800" type="text"
               placeholder="Search for articles..." value={props.value}
               onKeyPress={(e) => {
                   if (props.onKeyPress) {
                       props.onKeyPress(e);
                   }
               }}
               onChange={(e) => {
                   if (props.onChange != null) {
                       props.onChange(e.target.value)
                   }
               }
               }/>
    </div>
}

export {SearchBar};
