import React from "react";
import {Article} from "../../types";
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import {buildArticleRoute} from "../../util/routes";

interface FooterArticleProps {
    article: Article;
}

const FooterArticle = (props: FooterArticleProps) => {
    const article = props.article;

    return (
        <div className="space-y-2 h-20 flex md:flex-col gap-5 md:gap-0">
            <Link to={buildArticleRoute(article.slug)}>
                <GatsbyImage
                    loading="lazy"
                    className="h-20 w-36 rounded mb-2 mx-auto hover:opacity-75"
                    alt={props.article.contentImage.image.alt}
                    image={props.article.contentImage.image.gatsbyImageData}
                />
            </Link>

            <Link
                to={buildArticleRoute(article.slug)}
                className="text-gray-600 dark:text-gray-300 font-medium text-xs leading-snug block hover:text-green-500 dark:hover:text-green-500 w-36"
            >
                {article.title}
            </Link>
        </div>
    );
};

export {FooterArticle};
