import * as React from "react";
import clsx from "clsx";

interface PeriodProps {
    children?: string;
    color?: string;
}

const Period = (props: PeriodProps) => {
    return <span
        className={clsx(props.color ? props.color : "text-blue-600")}>{props.children ? props.children : "."}</span>
}

export {Period};
