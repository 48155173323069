module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","name":"Gregory Gaines","short_name":"Gregory Gaines","start_url":"/","background_color":"#3B82F6","theme_color":"#3B82F6","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"750d1513e99cbd87914c4ccee483fb68"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
