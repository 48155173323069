import * as React from "react";
import {useEffect, useState} from "react";
import {DarkModeToggle} from "../DarkModeToggle/DarkModeToggle";
import clsx from "clsx";
import {Link} from "gatsby";
import {routes} from "../../util/routes";
import {useMatch} from "@reach/router";
import {generateRandomColor} from "../../util/colors";
import {ProgressBar} from "../ProgressBar";
import {useScrollPosition} from "../../hooks/useScrollPosition";
import {Period} from "../Period";
import * as path from "path";
import {TwitterPopup} from "../TwitterPopup";

// TODO CHECK FOR MEMORY LEAKS
const Header = ({ location, hideSidebar, setHideSidebar, setUserInteractedWithSidebar }) => {
  const [isOpen, setIsOpen] = useState(false);
  let pathname = location.pathname;
  let isFirefoxBrowser = false;
  if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
    isFirefoxBrowser = navigator.userAgent.includes("Firefox");
  }

  const scrollPosition = useScrollPosition();

  const matchBlog = useMatch("/blog/:slug") != null;

  let targetScrollPosition = 80;
  if (!matchBlog) {
    // Do not show on non blog pages
    targetScrollPosition = 9999999;
  } else if (pathname == "/blog/what-is-the-time-complexity-arrays-and-collections-sort/") {
    targetScrollPosition = 67;
  } else if (pathname == "/blog/refersh-gatsby-content-without-restarting-development-server/" ||
      pathname == "/blog/how-to-properly-hash-and-salt-passwords-in-golang-bcrypt/" ||
  pathname == "/blog/how-to-hash-and-salt-passwords-in-golang-using-sha512-and-why-you-shouldnt/" ||
  pathname == "/blog/how-to-use-function-pointers-in-java/") {
    targetScrollPosition = 60;
  }

  useEffect(() => {
    // TODO use unique identifier for page identifying
    setIsOpen(false);
  }, [pathname]);

  return (
    <>
      <header
        className={clsx(
          "dark:bg-gray-800 md:px-0 top-0 text-gray-600 body-font sticky w-full z-40 backdrop-filter backdrop-blur-lg bg-opacity-70",
          isFirefoxBrowser && "firefox-blur",
          !isOpen && "border-b"
        )}
        style={{overscrollBehavior: "none"}}
      >
        <div className={clsx("mx-auto max-w-6xl mx-auto px-5")}>
          <div className="mx-auto px-10 justify-between flex flex-wrap py-4 px-0 flex-col md:flex-row items-center hidden md:flex">
            <span className="inline-flex gap-4">
              <button
                className="hidden lg:block focus:ring-4"
                aria-label="Sidebar hamburger"
                onClick={() => {
                  setHideSidebar(!hideSidebar);
                  setUserInteractedWithSidebar(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-800 dark:text-gray-300 subpixel-antialiased rounded"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
              <Link
                to={routes.home}
                className="focus:ring-4 flex title-font font-semibold text-gray-800 items-center text-xl hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-900 px-2 py-2 rounded"
              >
                Gregory Gaines
                <Period />
              </Link>
            </span>

            <nav className="header-nav flex flex-wrap items-center text-base justify-center text-md gap-4 xl:gap-8 xl:mr-8">
              {/* <Link to={routes.home}
                              className={clsx(`hover:bg-gray-100 font-bold hidden lg:block font-medium hover:${generateRandomColor(6)} dark:hover:bg-gray-700 px-3 py-2 rounded`, pathname === routes.home ? generateRandomColor(6) : "text-gray-600 dark:text-gray-300", pathname === routes.home && "font-bold")}>Home</Link>
                        */}
              {/*<Link*/}
              {/*    to={routes.home}*/}
              {/*    className={clsx(*/}
              {/*        ` hover:bg-gray-100 font-semibold hover:${generateRandomColor(*/}
              {/*            0*/}
              {/*        )} dark:hover:${generateRandomColor(*/}
              {/*            0*/}
              {/*        )} dark:hover:bg-gray-900 px-3 py-2 rounded`,*/}
              {/*        pathname === routes.home*/}
              {/*            ? generateRandomColor(6)*/}
              {/*            : "text-gray-800 dark:text-gray-300",*/}
              {/*        (pathname === routes.home) && "font-bold bg-gray-100 dark:bg-gray-900"*/}
              {/*    )}*/}
              {/*>*/}
              {/*    Home*/}
              {/*</Link>*/}
              <Link
                to={routes.blog}
                className={clsx(
                  ` hover:bg-gray-100 font-semibold hover:${generateRandomColor(
                    0
                  )} dark:hover:${generateRandomColor(
                    0
                  )} dark:hover:bg-gray-900 px-3 py-2 rounded`,
                  pathname === routes.blog || matchBlog
                    ? generateRandomColor(0)
                    : "text-gray-800 dark:text-gray-300",
                  (pathname === routes.blog || matchBlog) &&
                    "font-bold bg-gray-50 dark:bg-gray-900"
                )}
              >
                Blog
              </Link>
              <Link
                to={routes.projects}
                className={clsx(
                  ` hover:bg-gray-100 font-semibold hover:${generateRandomColor(
                    1
                  )} dark:hover:${generateRandomColor(
                    1
                  )} dark:hover:bg-gray-900 px-3 py-2 rounded`,
                  pathname === routes.projects
                    ? generateRandomColor(1)
                    : "text-gray-800 dark:text-gray-300",
                  pathname === routes.projects &&
                    "font-bold bg-gray-50 dark:bg-gray-900"
                )}
              >
                Projects
              </Link>
              <Link
                to={routes.anime}
                className={clsx(
                  ` hover:bg-gray-100 font-semibold hover:${generateRandomColor(
                    4
                  )} dark:hover:${generateRandomColor(
                    4
                  )}  dark:hover:bg-gray-900 px-3 py-2 rounded`,
                  pathname === routes.anime
                    ? generateRandomColor(4)
                    : "text-gray-800 dark:text-gray-300",
                  pathname === routes.anime &&
                    "font-bold bg-gray-50 dark:bg-gray-900"
                )}
              >
                Anime
              </Link>
              <Link
                to={routes.aboutMe}
                className={clsx(
                  ` hover:bg-gray-100 font-semibold hover:${generateRandomColor(
                    2
                  )} dark:hover:${generateRandomColor(
                    2
                  )}  dark:hover:bg-gray-900 px-3 py-2 rounded`,
                  pathname === routes.aboutMe
                    ? generateRandomColor(2)
                    : "text-gray-800 dark:text-gray-300",
                  pathname === routes.aboutMe &&
                    "font-bold bg-gray-50 dark:bg-gray-900"
                )}
              >
                About Me
              </Link>
              <Link
                to={routes.resume}
                className={clsx(
                  ` hover:bg-gray-100 font-semibold hover:${generateRandomColor(
                    3
                  )} dark:hover:${generateRandomColor(
                    3
                  )}  dark:hover:bg-gray-900 px-3 py-2 rounded`,
                  pathname === routes.resume
                    ? generateRandomColor(3)
                    : "text-gray-800 dark:text-gray-300",
                  pathname === routes.resume &&
                    "font-bold bg-gray-50 dark:bg-gray-900"
                )}
              >
                Resume
              </Link>
            </nav>
            <DarkModeToggle />
          </div>

          <div className="md:hidden flex items-center justify-between h-20">
            <Link
              to={routes.home}
              className=" flex text-gray-800 title-font font-medium items-center font-semibold text-xl dark:text-gray-300"
            >
              Gregory Gaines
              <Period />
            </Link>

            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="ml-auto my-auto dark:bg-gray-900 bg-gray-200 inline-flex items-center justify-center p-2 rounded dark:text-gray-400 text-gray-800 hover:text-gray-800 dark:hover:text-white dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>

          {
            isOpen && (
                <nav className="md:hidden shadow-md border rounded">
                  <div
                      className="gap-4 flex flex-col p-2 bg-white dark:bg-gray-800"
                  >
                    <Link
                        to={routes.home}
                        className={clsx(
                            ` hover:bg-gray-200 dark:hover:bg-gray-900 px-3 py-2 rounded hover:${generateRandomColor(6)}`,
                            pathname === routes.home && generateRandomColor(6),
                            pathname === routes.home
                                ? "font-bold"
                                : "dark:text-gray-300"
                        )}
                    >
                      Home
                    </Link>
                    <Link
                        to={routes.blog}
                        className={clsx(
                            ` hover:bg-gray-200 dark:hover:bg-gray-900 px-3 py-2 text-gray-700 rounded-md hover:${generateRandomColor(
                                0
                            )}`,
                            (pathname === routes.blog || matchBlog) &&
                            generateRandomColor(0),
                            pathname === routes.blog || matchBlog
                                ? "font-bold"
                                : "dark:text-gray-300"
                        )}
                    >
                      Blog
                    </Link>
                    <Link
                        to={routes.projects}
                        className={clsx(
                            ` hover:bg-gray-200 dark:hover:bg-gray-900 px-3 py-2 text-gray-700 rounded-md hover:${generateRandomColor(
                                1
                            )}`,
                            pathname === routes.projects && generateRandomColor(1),
                            pathname === routes.projects
                                ? "font-bold"
                                : "dark:text-gray-300"
                        )}
                    >
                      Projects
                    </Link>
                    <Link
                        to={routes.anime}
                        className={clsx(
                            ` hover:bg-gray-200 dark:hover:bg-gray-900 px-3 py-2 text-gray-700 rounded-md hover:${generateRandomColor(
                                4
                            )}`,
                            pathname === routes.anime && generateRandomColor(4),
                            pathname === routes.anime
                                ? "font-bold"
                                : "dark:text-gray-300"
                        )}
                    >
                      Anime
                    </Link>
                    <Link
                        to={routes.aboutMe}
                        className={clsx(
                            ` hover:bg-gray-200 dark:hover:bg-gray-900 px-3 py-2 text-gray-700 rounded-md hover:${generateRandomColor(
                                2
                            )}`,
                            pathname === routes.aboutMe && generateRandomColor(2),
                            pathname === routes.aboutMe
                                ? "font-bold"
                                : "dark:text-gray-300"
                        )}
                    >
                      About Me
                    </Link>
                    <Link
                        to={routes.resume}
                        className={clsx(
                            `hover:bg-gray-200 dark:hover:bg-gray-900 px-3 py-2 text-gray-700 rounded-md hover:${generateRandomColor(
                                3
                            )}`,
                            pathname === routes.resume && generateRandomColor(3),
                            pathname === routes.resume
                                ? "font-bold"
                                : "dark:text-gray-300"
                        )}
                    >
                      Resume
                    </Link>
                  </div>

                  <div className="dark:bg-gray-900 bg-gray-100 py-3 rounded-b">
                    <DarkModeToggle />
                  </div>
                </nav>
            )
          }
        </div>
      </header>
      {/*<TwitterPopup visible={scrollPosition >= targetScrollPosition} />*/}
      {matchBlog && (
        <ProgressBar
          styles={{ top: "4.8rem" }}
          className="sticky"
          scrollPosition={scrollPosition}
        />
      )}
    </>
  );
};

export { Header };
