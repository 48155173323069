import React, {useContext} from 'react';
import ThemeContext from "../../context/ThemeContext";
// import { DarkModeToggle as CuteDarkModeToggle } from "react-dark-mode-toggle-2";
import clsx from "clsx";
import LottiePlayer from "react-lottie-player/dist/LottiePlayerLight";
import animationData from "../../content/animationData.json";

// Allows accessing DarkModeToggleProps type via DarkModeToggle.Props
declare namespace DarkModeToggle {
    export type Props = {
        /** Whether or not the toggle is currently in dark-mode */
        readonly isDarkMode: boolean;

        /** Use this to update the state that controls the `isDarkMode` prop */
        readonly onChange: (isDarkMode: boolean) => void;

        /** Size of the component. Numbers = pixels. Strings = "<number><unit>" e.g. "20px" or "1.5%" (default = "85px"); */
        readonly size?: number | string;

        /** Use this to control the speed at which the toggle animation occurs (default = 2.5) */
        readonly speed?: number;

        /** Optional className prop for the <button/> element (default = "") */
        readonly className?: string;
    };
}

const arePropsEqual = (
    prevProps: DarkModeToggle.Props,
    nextProps: DarkModeToggle.Props
) =>
    prevProps.size === nextProps.size &&
    prevProps.isDarkMode === nextProps.isDarkMode &&
    prevProps.speed === nextProps.speed &&
    prevProps.className === nextProps.className;

type ParsedUnit = [number, string];

const parseUnit = (input: string | number): ParsedUnit => {
    const str = `${input}`;
    const value = parseFloat(str);
    const unit = str.match(/[\d.\-+]*\s*(.*)/)?.[1];

    return [isNaN(value) ? 0 : value, unit ? unit : "px"];
};

const lottieStyles = (
    isLottieReady: boolean,
    sizeValue: number,
    sizeUnit: string
): object => {
    return {
        display: isLottieReady ? "flex" : "none",
        alignItems: "center",
        justifyContent: "center",
        marginTop: `${sizeValue * -0.575}${sizeUnit}`,
        marginLeft: `${sizeValue * -0.32}${sizeUnit}`,
        width: `${sizeValue * 1.65}${sizeUnit}`,
        height: `${sizeValue * 1.65}${sizeUnit}`,
    };
};

const buttonStyles = (sizeValue: number, sizeUnit: string): object => {
    return {
        cursor: "pointer",
        overflow: "hidden",
        width: `${sizeValue}${sizeUnit}`,
        height: `${sizeValue * 0.5}${sizeUnit}`,
        appearance: "none",
        border: "none",
        backgroundColor: "transparent",
        padding: 0,
    };
};

const CuteDarkModeToggle = React.memo<DarkModeToggle.Props>(
    ({isDarkMode, onChange, size = 85, speed = 1.3, className = ""}) => {
        const [sizeValue, sizeUnit] = parseUnit(size);
        const [isLottieReady, setLottieReady] = React.useState(false); // Hide the toggle until Lottie is ready
        const [isReadyToAnimate, setReadyToAnimate] = React.useState(false); // Disable animation of toggle until first click

        const segmentToGoTo: number = isDarkMode ? 51 : 2;
        const segmentsToPlay = isDarkMode ? [2, 50] : [51, 96];

        return (
            <button
                aria-label="Toggle light mode"
                onClick={() => {
                    if (!isReadyToAnimate) setReadyToAnimate(true);
                    onChange(!isDarkMode);
                }}
                className={className}
                style={buttonStyles(sizeValue, sizeUnit)}
            >
                <LottiePlayer
                    style={lottieStyles(isLottieReady, sizeValue, sizeUnit)}
                    play={isReadyToAnimate}
                    speed={speed}
                    animationData={animationData}
                    loop={false}
                    segments={segmentsToPlay}
                    goTo={segmentToGoTo}
                    onEnterFrame={() => setLottieReady(true)}
                />
            </button>
        );
    },
    arePropsEqual
);

const DarkModeToggle = (props) => {
    const {dark, setDark, toggleDark} = useContext(ThemeContext);

    return (
        <div className={clsx("flex flex-col", props.className && props.className)}>
            <CuteDarkModeToggle
                className="mx-auto md:mx-0"
                onChange={() => {
                    toggleDark()
                }}
                isDarkMode={dark}
                size={60}
                speed={1.9}
                aria-label="Toggle light mode"
            />

            {/*<label className="text-xs mx-auto dark:text-gray-300">*/}
            {/*    { dark ? 'Dark' : 'Light' } Mode*/}
            {/*</label>*/}
        </div>
    );
};

export {DarkModeToggle};
