import * as React from "react";
import {Anchor, Article} from "../../types";
import {routes} from "../../util/routes";
import {Socials} from "../Socials";
import {graphql, Link, useStaticQuery} from "gatsby";
import {Period} from "../Period";
import {FooterArticle} from "./FooterArticle";
import {GatsbyImage} from "gatsby-plugin-image";
import {getImageDataByHandle} from "../../util/image";

interface TechStackItem {
  imageSrc: string | any;
  title: string;
  alt: string;
  href: string;
}

const techStackItems: TechStackItem[] = [
  {
    imageSrc: "2brwHJXERlC3iuLy716W",
    title: "View build with React",
    alt: "React",
    href: "https://reactjs.org/",
  },
  {
    imageSrc: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="48px"
        height="48px"
      >
        <path
          fill="#1976d2"
          d="M38.193,18.359c-0.771-2.753-2.319-5.177-4.397-7.03l-4.598,4.598 c1.677,1.365,2.808,3.374,3.014,5.648v1.508c0.026,0,0.05-0.008,0.076-0.008c2.322,0,4.212,1.89,4.212,4.212S34.61,31.5,32.288,31.5 c-0.026,0-0.05-0.007-0.076-0.008V31.5h-6.666H24V38h8.212v-0.004c0.026,0,0.05,0.004,0.076,0.004C38.195,38,43,33.194,43,27.288 C43,23.563,41.086,20.279,38.193,18.359z"
        />
        <path
          fill="#ffe082"
          d="M19.56,25.59l4.72-4.72c-0.004-0.005-0.008-0.009-0.011-0.013l-4.717,4.717 C19.554,25.579,19.557,25.584,19.56,25.59z"
          opacity=".5"
        />
        <path
          fill="#90caf9"
          d="M19.56,25.59l4.72-4.72c-0.004-0.005-0.008-0.009-0.011-0.013l-4.717,4.717 C19.554,25.579,19.557,25.584,19.56,25.59z"
          opacity=".5"
        />
        <path
          fill="#ff3d00"
          d="M24,7.576c-8.133,0-14.75,6.617-14.75,14.75c0,0.233,0.024,0.46,0.035,0.69h6.5 c-0.019-0.228-0.035-0.457-0.035-0.69c0-4.549,3.701-8.25,8.25-8.25c1.969,0,3.778,0.696,5.198,1.851l4.598-4.598 C31.188,9.003,27.761,7.576,24,7.576z"
        />
        <path
          fill="#90caf9"
          d="M15.712,31.5L15.712,31.5c-0.001,0-0.001,0-0.002,0c-0.611,0-1.188-0.137-1.712-0.373 l-4.71,4.71C11.081,37.188,13.301,38,15.71,38c0.001,0,0.001,0,0.002,0v0H24v-6.5H15.712z"
          opacity=".5"
        />
        <path
          fill="#4caf50"
          d="M15.712,31.5L15.712,31.5c-0.001,0-0.001,0-0.002,0c-0.611,0-1.188-0.137-1.712-0.373l-4.71,4.71 C11.081,37.188,13.301,38,15.71,38c0.001,0,0.001,0,0.002,0v0H24v-6.5H15.712z"
        />
        <path
          fill="#ffc107"
          d="M11.5,27.29c0-2.32,1.89-4.21,4.21-4.21c1.703,0,3.178,1.023,3.841,2.494l4.717-4.717 c-1.961-2.602-5.065-4.277-8.559-4.277C9.81,16.58,5,21.38,5,27.29c0,3.491,1.691,6.59,4.288,8.547l4.71-4.71 C12.53,30.469,11.5,28.999,11.5,27.29z"
        />
      </svg>
    ),
    title: "Hosted on Google Cloud Platform",
    alt: "Google Cloud Platform",
    href: "https://cloud.google.com/",
  },
  {
    imageSrc: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 54 33"
        width="48px"
        height="48px"
      >
        <g clipPath="url(#prefix__clip0)">
          <path
            fill="#38bdf8"
            fillRule="evenodd"
            d="M27 0c-7.2 0-11.7 3.6-13.5 10.8 2.7-3.6 5.85-4.95 9.45-4.05 2.054.513 3.522 2.004 5.147 3.653C30.744 13.09 33.808 16.2 40.5 16.2c7.2 0 11.7-3.6 13.5-10.8-2.7 3.6-5.85 4.95-9.45 4.05-2.054-.513-3.522-2.004-5.147-3.653C36.756 3.11 33.692 0 27 0zM13.5 16.2C6.3 16.2 1.8 19.8 0 27c2.7-3.6 5.85-4.95 9.45-4.05 2.054.514 3.522 2.004 5.147 3.653C17.244 29.29 20.308 32.4 27 32.4c7.2 0 11.7-3.6 13.5-10.8-2.7 3.6-5.85 4.95-9.45 4.05-2.054-.513-3.522-2.004-5.147-3.653C23.256 19.31 20.192 16.2 13.5 16.2z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="prefix__clip0">
            <path fill="#fff" d="M0 0h54v32.4H0z" />
          </clipPath>
        </defs>
      </svg>
    ),
    title: "Front-end styled with TailwindCSS",
    alt: "TailwindCSS",
    href: "https://tailwindcss.com/",
  },
  {
    imageSrc: "6HUdPhssSJOGI9xEpXwV",
    title: "Headless CMS using GraphCMS",
    alt: "GraphCMS",
    href: "https://graphcms.com/",
  },
  {
    imageSrc: "I1lAvDTEQnqcmsrkeMBy",
    title: "Statically generated with Gatsby",
    alt: "Gatsby",
    href: "https://www.gatsbyjs.com/",
  },
];

const usefulLinks: Anchor[] = [
  {
    label: "Home",
    url: routes.home,
  },
  {
    label: "Blog",
    url: routes.blog,
  },
  {
    label: "Projects",
    url: routes.projects,
  },
  {
    label: "Resume",
    url: routes.resume,
  },
];

// FIX FORMATTING BETWEEN TECH STACK AND USEFUL LINKS
const Footer = () => {
  const data = useStaticQuery<GatsbyTypes.GetFooterQuery>(graphql`
    query GetFooter {
      site {
        siteMetadata {
          kofi
          paypal
          patreon
        }
      }

      allGraphCmsArticle(
        filter: {
          stage: { eq: PUBLISHED }
          slug: {
            in: [
              "what-is-the-time-complexity-arrays-and-collections-sort"
              "how-to-hash-and-salt-passwords-in-golang-using-sha512-and-why-you-shouldnt"
              "how-to-properly-hash-and-salt-passwords-in-golang-bcrypt"
            ]
          }
        }
      ) {
        nodes {
          slug
          tags {
            label
          }
          title
          created
          updated
          readTimeMinutes
          contentImage {
            image {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 70
                width: 144
                height: 80
              )
              alt
            }
          }
          excerpt
          category {
            label
          }
        }
      }

      allGraphCmsAsset(
        filter: {
          handle: {
            in: [
              "I1lAvDTEQnqcmsrkeMBy"
              "6HUdPhssSJOGI9xEpXwV"
              "DJwnCPUTEmD4WMpaKn1E"
              "2brwHJXERlC3iuLy716W"
            ]
          }
          stage: { eq: PUBLISHED }
        }
      ) {
        edges {
          node {
            id
            gatsbyImageData(
              quality: 70
              placeholder: BLURRED
              width: 28
              height: 28
            )
            handle
          }
        }
      }
    }
  `);

  const supportMes: Anchor[] = [
    {
      label: "Patreon",
      url: data.site.siteMetadata.patreon,
    },
    {
      label: "Ko-fi",
      url: data.site.siteMetadata.kofi,
    },
    {
      label: "PayPal",
      url: data.site.siteMetadata.paypal,
      noRel: true,
    },
  ];

  return (
    <footer className="border-t mx-auto">
      <div className="flex justify-center gap-5 xl:gap-20 p-5 mx-auto flex-wrap lg:flex-none max-w-7xl container">
        <div className="col-span-1 mb-10 md:mb-0  md:text-left md:mt-0 order-first">
          <nav className="flex gap-2 my-auto justify-center md:justify-start">
            {techStackItems.map((techStackItem) => (
              <a
                key={techStackItem.title}
                className="w-9 h-9 flex justify-center items-center text-center antialiased rounded-full p-1 transform hover:scale-105"
                href={techStackItem.href}
                title={techStackItem.title}
                target="_blank"
                rel="noreferrer"
              >
                {techStackItem.alt === "Google Cloud Platform" ||
                techStackItem.alt === "TailwindCSS" ? (
                  techStackItem.imageSrc
                ) : (
                  <GatsbyImage
                    loading="lazy"
                    alt={techStackItem.alt}
                    image={getImageDataByHandle(
                      data.allGraphCmsAsset,
                      techStackItem.imageSrc
                    )}
                  />
                )}
              </a>
            ))}
          </nav>
          <p className="my-1 text-xs leading-normal text-gray-600 dark:text-gray-300">
            Powered by React, Google Cloud, TailwindCSS,
            <br /> GraphCMS, Gatsby, and optimism!
            <br />
            Developed by{" "}
            <Link
              className="underline hover:text-green-500 text-green-600 font-medium"
              to={routes.aboutMe}
            >
              Gregory Gaines
            </Link>
            .
          </p>
        </div>

        <div className="flex gap-x-20 gap-y-10 lg:gap-32 flex-wrap lg:flex-none">
          <nav>
            <h3 className="text-sm font-semibold uppercase dark:text-gray-300 mb-2">
              Useful
            </h3>
            {usefulLinks.map((usefulLink) => {
              return (
                <Link
                  key={usefulLink.label}
                  className="dark:text-gray-300 flex mb-3 text-sm font-medium text-gray-600 transition md:mb-2 hover:text-green-500 dark:hover:text-green-500"
                  rel={usefulLink.noRel ? "noreferrer noopener" : ""}
                  to={usefulLink.url}
                >
                  {usefulLink.label}
                </Link>
              );
            })}
          </nav>

          <nav>
            <h3 className="text-sm font-semibold uppercase dark:text-gray-300 mb-2">
              Support Me
            </h3>
            {supportMes.map((me) => {
              return (
                <a
                  key={me.label}
                  className="dark:text-gray-300 flex mb-3 text-sm font-medium text-gray-600 transition md:mb-2 hover:text-green-500 dark:hover:text-green-500"
                  href={me.url}
                  rel={me.noRel ? "noreferrer noopener" : "noopener"}
                  target="_blank"
                >
                  {me.label}
                </a>
              );
            })}
          </nav>

          <div className="md:gap-8 col-span-full md:col-span-1">
            <div>
              <h3 className="text-sm font-semibold uppercase dark:text-gray-300 mb-2">
                Popular Posts
              </h3>
              <nav className="flex flex-col gap-2 md:flex-row">
                {data.allGraphCmsArticle.nodes.map((node: Article) => {
                  return <FooterArticle article={node} key={node.title} />;
                })}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 dark:bg-gray-900 dark:text-gray-300 mt-16 py-5 border-t">
        <Socials isFooter className="text-center" />
        <p className="text-sm mt-4 text-center font-medium">
          &copy; {new Date().getFullYear()}{" "}
          <Link className="underline text-blue-600 hover:text-blue-500" to="/">
            Gregory Gaines
          </Link>{" "}
          — All Rights Reserved
          <Period />
        </p>
      </div>
    </footer>
  );
};

// TODO REPLACE FLEX WITH space-y-2
export { Footer };
