import React from "react";
import {Helmet} from "react-helmet";
import {useLocation} from "@reach/router";

interface Type {
  type: string;
  child: {};
}

interface SeoProps {
  // Meta
  title?: string;
  description?: string;

  // Open Graph
  type?: Type;
  url?: string;
  image?: string;
  alt?: string;

  errorPage?: boolean;
  blogPage?: boolean;
  categoryPage?: boolean;
  tagPage?: boolean;
  datePublished?: Date;
  dateModified?: Date;
}

const name = "Gregory Gaines";
const description =
  "I'm Gregory, a full-stack developer who loves computer science and blogging. I'm glad you're here, joining me to explore programming one byte at a time.";
const imageUrl = "https://media.graphcms.com/oxKRhCGKSk2MFJZNIVTE";
//const twitterHandle = "@gregorygaines";

const Seo = (props: SeoProps) => {
  const location = useLocation();

  /* JavaScript Object Notation for Linked Data format */
  const jsonLD = [
    // Base WebSite LD
    {
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: name,
      url: "https://www.gregorygaines.com/",
    },
  ];

  if (props.blogPage) {
    jsonLD.push({
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://www.gregorygaines.com",
      },
      url: props.url || "https://www.gregorygaines.com",
      name: props.title || "Gregory Gaines",
      image: props.image || "",
      headline: props.title || "Gregory Gaines",
      author: {
        "@type": "Person",
        name: "Gregory Gaines",
        url: "https://www.gregorygaines.com",
      },
      description: props.description || description,
      datePublished: props.datePublished,
      dateModified: props.dateModified,
      publisher: {
        "@type": "Person",
        name: "Gregory Gaines",
        url: "https://www.gregorygaines.com",
      },
    });
  }

  const itemList: any = [];

  if (location.pathname !== "/") {
    if (props.blogPage || props.categoryPage || props.tagPage) {
      itemList.push({
        "@type": "ListItem",
        position: 1,
        name: "Blog",
        item: "https://www.gregorygaines.com/blog/",
      });

      itemList.push({
        "@type": "ListItem",
        position: 2,
        name: props.title,
        item: location.href,
      });
    }
  }

  return (
    <Helmet>
      {/* Primary Meta tags */}
      <title>{props.title ? `${props.title} | ${name}` : name}</title>
      {/*{!props.errorPage && <link rel="canonical" href={location.href}/>}*/}

      <meta name="description" content={props.description || description} />
      {!props.errorPage && <meta name="robots" content="follow, index" />}
      <meta name="author" content="Gregory Gaines" />

      {/* Open Graph */}
      {!props.type ? (
        <meta property="og:type" content="website" />
      ) : (
        <meta property="og:type" content={props.type.type} />
      )}
      {props.type &&
        Object.entries(props.type?.child).forEach(([key, value]) => {
          return <meta property={key} content={value ? value : ""} />;
        })}
      <meta property="og:site_name" content={name} />
      <meta property="og:url" content={location.href} />
      <meta
        property="og:title"
        content={props.title ? `${props.title} | ${name}` : name}
      />
      <meta
        property="og:description"
        content={props.description ? props.description : description}
      />
      <meta
        property="og:image"
        content={props.image ? props.image : imageUrl}
      />

      {/* Twitter Tags */}
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:title"
        content={props.title ? `${props.title} | ${name}` : name}
      />
      <meta
        name="twitter:description"
        content={props.description ? props.description : description}
      />
      <meta
        name="twitter:image"
        content={props.image ? props.image : imageUrl}
      />
      <meta
        name="twitter:image:alt"
        content={props.alt ? props.alt : "Gregory Gaines"}
      />
      {/* <meta
                name="twitter:site"
                content={twitterHandle}
            />
            <meta
                name="twitter:creator"
                content={twitterHandle}
            /> */}

      {jsonLD && (
        <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
      )}
    </Helmet>
  );
};

export { Seo };
