import * as React from 'react';

const defaultState = {
    dark: false,
    toggleDark: () => {
    },
    setDark: (val) => {
    },
};

const ThemeContext = React.createContext(defaultState);

// Getting dark mode information from OS!
// You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.
const isSystemDarkMode = () => {
    if (!window.matchMedia) {
        return true;
    }

    return window.matchMedia('(prefers-color-scheme: dark)').matches;
};

class ThemeProvider extends React.Component {
    state = {
        dark: false,
    };

    toggleDark = () => {
        const dark = !this.state.dark;
        localStorage.setItem('theme', dark ? 'dark' : 'light');
        this.setState({
            dark: dark
        });
    };

    componentDidMount() {
        // Getting dark mode value from localStorage.
        let themeKey = localStorage.getItem('theme');

        // Getting dark mode value from os.
        if (themeKey === null) {
            themeKey = isSystemDarkMode() ? 'dark' : 'light';
            // Set in localstorage
            localStorage.setItem('theme', themeKey);
        }

        this.setState({dark: themeKey === 'dark'});
    }

    setDark = (dark) => {
        if (dark !== this.state.dark) {
            this.toggleDark()
        }
    }

    render() {
        const {children} = this.props;
        const {dark} = this.state;

        return (
            <ThemeContext.Provider
                value={{dark: dark, toggleDark: this.toggleDark, setDark: this.setDark}}
            >
                {children}
            </ThemeContext.Provider>
        );
    }
}

export default ThemeContext;

export {ThemeProvider};
