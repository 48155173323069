import React from "react";
import clsx from "clsx";

interface ProgressBarProps {
    scrollPosition: number;
    className?: string;
    progressBarClassName?: string;

    [x: string]: any;
}

const ProgressBar = (props: ProgressBarProps) => (
    <div
        className={clsx(
            "h-1.5 bg-gray-200 z-50",
            props.className,
        )}
        style={props.styles}
    >
    <span
        className={clsx(
            'bg-gradient-to-r from-blue-500 via-red-500 via-green-500 to-indigo-500 flex h-1.5 z-50',
            props.progressBarClassName
        )}
        style={{width: `${props.scrollPosition}%`, transition: "width 0.6s ease"}}
    />
    </div>
);

export {ProgressBar};
