import {useLocation, useMatch} from "@reach/router";
import clsx from "clsx";
import React, {useContext, useEffect, useState} from "react";
import ThemeContext from "../context/ThemeContext";
import {Footer} from "./Footer";
import {Header} from "./Header";
import {Seo} from "./Seo";
import {Sidebar} from "./Sidebar";
import {reportWebVitals} from "../util/reportWebVitals";
import {useWindowSize} from "../hooks/useWindowSize";

const reportAnalytics = ({id, name, value}) => {

    if (window.gtag) {
        window.gtag('event', name, {
            'event_category': 'Web Vitals',
            'value': Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
            'event_label': id, // id unique to current page load
            'non_interaction': true, // avoids affecting bounce rate
        })
    }
}

const shouldHideSidebar = (width, isArticle) => {
    if (width === undefined) {
        return true;
    }

    if (isArticle) {
        return width < 1353;
    }
    return width < 1752;
}

const hashChangeEvent = (e) => {
    e.preventDefault();
}

const Layout = ({children}) => {
    const {dark, setTheme} = useContext(ThemeContext);
    const [expanded, setExpanded] = useState(new Set());
    const location = useLocation();
    const size = useWindowSize();
    const [userInteractedWithSidebar, setUserInteractedWithSidebar] = useState(false);
    const [hideSidebar, setHideSidebar] = useState(true);
    const [currPage, setCurrPage] = useState("");
    const matchBlog = useMatch("/blog/:slug/") != null;

    // TODO Track page view
    useEffect(() => {
        if (currPage !== location.pathname) {
            reportWebVitals(reportAnalytics);
            setCurrPage(location.pathname);
        }

        if (!userInteractedWithSidebar) {
            setHideSidebar(shouldHideSidebar(size.width, matchBlog));
        }

        window.addEventListener("hashchange", hashChangeEvent);

        return () => {
            window.removeEventListener("hashchange", hashChangeEvent);
        }
    }, [location.pathname, size.width]);

    //  className={clsx(dark && "dark", "debug-screens")}

    return (
        //TODO remove debug screens
        <div className={dark ? "dark" : ""}>
            <div className="dark:text-gray-300 dark:bg-gray-800">
                <Seo/>
                <Header
                    location={location}
                    hideSidebar={hideSidebar}
                    setHideSidebar={setHideSidebar}
                    setUserInteractedWithSidebar={setUserInteractedWithSidebar}
                />
                <div className="flex">
                    {!hideSidebar && (
                        <Sidebar
                            expanded={expanded}
                            setExpanded={setExpanded}
                            location={location}
                            className="flex-none h-full"
                        />
                    )}
                    <div className={clsx("flex-auto", dark && "dark")}>
                        <main>{children}</main>
                        <Footer/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {Layout};
