import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {SearchBar} from "./SearchBar";
import clsx from "clsx";
import {graphql, Link, navigate, useStaticQuery} from "gatsby";
import {buildArticleRoute} from "../util/routes";
import {useMatch} from "@reach/router";

interface SidebarProps {
  className?: string;
  location?: any;
  expanded: Set<string>;
  setExpanded: any;
}

// TODO AUTOEXPAND ON WINDOW SIZE DESKTOP
const navigateToSearch = (searchVal: string) => {
  navigate("/blog?search=" + searchVal, { state: { search: searchVal } });
};

const Sidebar = (props: SidebarProps) => {
  const matchArticleRoute = useMatch("/blog/:slug");
  const matchBlogRoute = useMatch("/blog/");

  const pathname = props.location.pathname.slice(0, -1);

  const lastSegment = pathname.substring(pathname.lastIndexOf("/") + 1);

  const data = useStaticQuery<GatsbyTypes.GetCategoriesQuery>(graphql`
    query GetCategories {
      allGraphCmsCategory(
        filter: {
          stage: { eq: PUBLISHED }
          articles: { elemMatch: { stage: { eq: PUBLISHED } } }
        }
      ) {
        edges {
          node {
            id
            label
            articles {
              slug
              title
            }
          }
        }
      }
    }
  `);

  const [sidebarSearch, setSidebarSearch] = useState("");

  const handleToggleExpanded = useCallback(
    (id: string) => {
      props.setExpanded((prev: Set<string>) => {
        if (prev.has(id)) {
          const set = new Set([...props.expanded]);
          set.delete(id);
          return set;
        }

        return new Set(prev.add(id));
      });
    },
    [props.setExpanded, props.expanded]
  );

  useEffect(() => {
    // Expand dropdown that contains post
    const currCategory = data.allGraphCmsCategory.edges.find((category) => {
      const foundCategory = category.node.articles.find((article) => {
        if (article.slug === lastSegment) {
          return category;
        }
        return null;
      });

      if (foundCategory) {
        return foundCategory;
      }

      return null;
    });

    if (currCategory) {
      // @ts-ignore
      if (!props.expanded.has(currCategory.node.id)) {
        handleToggleExpanded(currCategory.node.id);
      }
    }
  }, [data.allGraphCmsCategory.edges, lastSegment]);

  // PUT BUTTON NEXT TO SEARCH BAR
  return (
    <aside
      className={clsx(
        "h-screen border-r w-72 fixed overflow-y-auto pb-40 hidden lg:block dark:bg-gray-800 bg-white z-50",
        props.className && props.className
      )}
      style={{ top: matchArticleRoute ? "5.2rem" : "4.8rem" }}
    >
      <div className="bg-gray-50 pl-8 py-2 text-sm font-bold border-b border-gray-100 dark:bg-gray-900 dark:text-gray-300">
        Table of Contents
      </div>
      <div className="hidden md:flex md:shrink flex-grow">
        <div className="flex flex-col w-80">
          {!matchBlogRoute && (
            <div className="px-2 my-3">
              <SearchBar
                value={sidebarSearch}
                onChange={(val) => {
                  setSidebarSearch(val);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    navigateToSearch(sidebarSearch);
                    setSidebarSearch("");
                  }
                }}
              />
            </div>
          )}
          <div className="flex flex-col flex-grow">
            <div className="flex flex-col flex-grow px-2">
              <div className="flex-1 dark:text-gray-300">
                {data.allGraphCmsCategory &&
                  data.allGraphCmsCategory.edges.map((category) => {
                    const isCategory = props.expanded.has(category.node.id);

                    return (
                      <section key={category.node.id}>
                        <button
                          className={clsx(
                            "inline-flex items-center w-full py-2 my-0.5 text-base transition dark:text-white duration-500 ease-in-out transform focus:shadow-outline hover:bg-gray-100 rounded px-2 dark:hover:bg-gray-900",
                            props.expanded.has(category.node.id)
                              ? "dark:bg-gray-900 text-blue-500 bg-gray-100 text-white"
                              : "text-gray-900"
                          )}
                          onClick={() => handleToggleExpanded(category.node.id)}
                        >
                          <svg
                            className={clsx(
                              "w-4 h-4 transition-transform transform duration-200 ease-in-out items-center ",
                              isCategory ? "" : "-rotate-90"
                            )}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                          <span className="ml-2 text-xs uppercase font-semibold">
                            {category.node.label}
                          </span>
                        </button>
                        <nav
                          role="navigation"
                          className={clsx(
                            "flex-col mb-2 ml-3 mt-0.5",
                            isCategory ? "flex" : "hidden"
                          )}
                        >
                          {category.node.articles.map((article) => {
                            const matchPageArticle =
                              article.slug === lastSegment;

                            return (
                              <Link
                                key={article.slug}
                                to={buildArticleRoute(article.slug)}
                                role="menuitem"
                                className={clsx(
                                  "flex items-center border-l-2 ml-1 border-gray-300 px-2 py-1 hover:bg-gray-100 dark:hover:bg-gray-900 font-medium",
                                  matchPageArticle &&
                                    "border-blue-500 bg-gray-50 dark:bg-gray-900 dark:text-blue-500 font-bold"
                                )}
                                style={{ fontSize: "13px" }}
                              >
                                {article.title}
                              </Link>
                            );
                          })}
                        </nav>
                      </section>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export { Sidebar };
