exports.routes = {
    home: '/',
    blog: '/blog/',
    anime: '/anime/',
    projects: '/projects/',
    aboutMe: '/me/',
    resume: '/resume/',
    supportMe: '/support-me/'
}

exports.buildCategoryRoute = (category) => {
    const categoryRoute = category.toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');

    return `/blog/category/${categoryRoute}/`;
}

exports.buildTagRoute = (tags) => {
    const tagRoute = tags.toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');

    return `/blog/tag/${tagRoute}/`;
}

exports.buildArticleRoute = (slug) => {
    return `/blog/${slug}/`;
}
