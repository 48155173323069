import {useEffect, useState} from "react";

const getDocHeight = () =>
    Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight,
    );

const useScrollPosition = (): number => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const calculateScrollDistance = () => {
        const scrollTop = window.pageYOffset;
        const winHeight = window.innerHeight;
        const docHeight = getDocHeight();
        const totalDocScrollLength = docHeight - winHeight;
        const currScrollPosition = Math.floor(
            (scrollTop / totalDocScrollLength) * 100,
        );
        setScrollPosition(currScrollPosition);
    };

    useEffect(() => {
        const listenToScrollEvent = () => {
            requestAnimationFrame(() => {
                calculateScrollDistance();
            });
        };

        document.addEventListener('scroll', listenToScrollEvent);

        return () => {
            document.removeEventListener('scroll', listenToScrollEvent);
        };
    }, []);

    return scrollPosition;
}

export {useScrollPosition};
