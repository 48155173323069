const colors = [
    "text-red-500",
    "text-yellow-500",
    "text-green-500",
    "text-blue-600",
    "text-indigo-500",
    "text-purple-500",
    "text-pink-500",
];

const borderColors = [
    "border-blue-500",
    "border-yellow-500",
    "border-indigo-500",
    "border-red-500",
    "border-green-500",
    "border-pink-500",
    "border-purple-500",
    "border-orange-500",
];

const bgColors = [
    "bg-blue-600",
    "bg-yellow-500",
    "bg-indigo-500",
    "bg-red-500",
    "bg-green-500",
    "bg-pink-500",
    "bg-purple-500",
];

export const generateRandomColor = (seed: number) => {
    const index = seed % colors.length;
    return colors[index];
}

export const generateBorderColor = (seed: number) => {
    const index = seed % colors.length;
    return borderColors[index];
}

export const generateBgColor = (seed: number) => {
    const index = seed % colors.length;
    return bgColors[index];
}
